import React from 'react'

import {
  FormInput,
  FormButtonsElement,
  FormRow,
  FormCol,
} from 'components/shared/Forms'

export default class ThirdStep extends React.Component {
  render() {
    const { initialData, visible } = this.props
    const { item_address, item_zip } = initialData

    const isValid = item_address != '' && item_zip != ''
    return (
      <div>
        <h3>Add Location of item</h3>
        <p>We will only share an approximate location with viewers</p>
        <FormRow>
          <FormCol>
            <FormInput label="Address">
              <input
                id="item_address"
                name="item_address"
                type="text"
                required
                defaultValue={item_address}
                onChange={this.props.onChange}
              />
            </FormInput>
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol>
            <FormInput label="Zipcode">
              <input
                id="item_zip"
                name="item_zip"
                type="text"
                required
                defaultValue={item_zip}
                onChange={this.props.onChange}
              />
            </FormInput>
          </FormCol>
        </FormRow>
        <FormButtonsElement {...this.props} disabled={!isValid} />
      </div>
    )
  }
}
