import React from 'react'

import { default as Layout } from 'layouts/AuthenticatedPage'
import SEO from 'components/shared/SEO'
import SubmitForm from 'components/SubmitPage'
import PageTitle from 'components/PageTitle'

class SubmitPage extends React.Component {
  state = {
    user: null,
  }

  componentDidMount = async () => {
    const user = await this.props.database.getCurrentUser()
    if (user.exists) {
      const data = user.data()
      this.setState({ user: data })
    }
  }

  onSubmit = async item => {
    try {
      await this.props.database.createItem(item)
    } catch (error) {
      return error
    }
  }

  render() {
    return (
      <>
        <PageTitle title="List an item" />
        <SubmitForm onSubmit={this.onSubmit} user={this.state.user} />
      </>
    )
  }
}

export default ({ location }) => (
  <Layout displayFooter={false} location={location} showBeacon={true}>
    <SEO />
    <SubmitPage />
  </Layout>
)
