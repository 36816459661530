import React from 'react'

import {
  FormInput,
  FormButtonsElement,
  Uploader,
  FormRow,
  FormCol,
} from 'components/shared/Forms'

export default class SecondStep extends React.Component {
  render() {
    const { initialData } = this.props
    const { item_pictures, item_description } = initialData

    const isValid = item_pictures.length != 0 && item_description != ''
    return (
      <>
        <h3 style={{ marginBottom: 0 }}>Add a photo</h3>
        {item_pictures && (
          <div className="item-picture-preview">
            {item_pictures.map(picture => (
              <img src={picture} height="100" width="100" />
            ))}
          </div>
        )}
        <FormRow>
          <FormCol>
            <Uploader
              id="file"
              name="item_pictures"
              multiple={true}
              required
              onUploadComplete={this.props.uploadImg}
            />
          </FormCol>
        </FormRow>
        <h3>Tell us about your item</h3>
        <FormRow>
          <FormCol>
            <FormInput label="Description">
              <textarea
                className="text-input"
                name="item_description"
                type="text"
                required
                maxLength="1600"
                rows="6"
                defaultValue={item_description}
                onChange={this.props.onChange}
              />
            </FormInput>
          </FormCol>
        </FormRow>
        <FormButtonsElement {...this.props} nextReady={isValid} />
      </>
    )
  }
}
