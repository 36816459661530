import React from 'react'
import Geocode from 'react-geocode'
import { navigate } from 'gatsby'

import { LoadingDots } from 'components/shared/Loading'
import ProgressFormElement from './elements/ProgressFormElement'
import { FirstStep, SecondStep, ThirdStep, ConfirmationStep } from './steps'
import Card from 'components/shared/Card'
import { Alert, FormContainer } from 'components/shared/Forms'

const slug = word => {
  if (!word) return
  return word
    .toLowerCase()
    .replace(' ', '-')
    .replace('& ', '')
}

export default class SubmitForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 1,
      item_name: '',
      item_pictures: [],
      item_type: '',
      item_subtype: '',
      price: 0,
      item_description: '',
      item_address: '',
      item_zip: '',
      item_city: '',
      item_state: '',
      item_lat: '',
      item_lng: '',
    }
  }

  componentDidMount() {
    Geocode.setApiKey('AIzaSyA_0G3ZKX89wmcQdG84SbwYrh0bMwotH7U')
  }

  _getAddress = () => {
    this._moveForward()

    let city,
      state,
      lat,
      lng = ''
    Geocode.fromAddress(this.state.zip).then(
      response => {
        // find city and state
        if (response.results[0]) {
          response.results[0].address_components.forEach(entry => {
            if (entry.types[0] == 'locality') {
              city = entry.long_name
            } else if (entry.types[0] == 'administrative_area_level_1') {
              state = entry.short_name
            }
          })
          // Get lat/lng based on zip and add a little noise
          lat =
            response.results[0].geometry.location.lat +
            (Math.random() * (0.05 - 0.01) + 0.01)
          lng =
            response.results[0].geometry.location.lng +
            (Math.random() * (0.05 - 0.01) + 0.01)
        }
        this.setState(
          {
            city: city,
            state: state,
            lat: lat,
            lng: lng,
          },
          () => {
            this._submit()
          }
        )
      },
      error => {
        console.error(error)
        // submit even if we can't get city and state
        this._submit()
      }
    )
  }

  _moveForward = () => {
    if (!this.form.reportValidity()) {
      return
    }
    this.setState({ currentStep: this.state.currentStep + 1 })
  }

  _moveBackward = () => {
    this.setState({ currentStep: this.state.currentStep - 1 })
  }

  _submit = () => {
    this.setState({ loading: true })
    let type_slug = slug(this.state.type)
    let subtype_slug = slug(this.state.subtype)
    let item = {
      name: this.state.name,
      pictures: this.state.pictures,
      type: type_slug,
      subtype: subtype_slug,
      price_d: this.state.price,
      description: this.state.description,
      street_address: this.state.address,
      zip: this.state.zip,
      city: this.state.city,
      state: this.state.state,
      lat: this.state.lat,
      lng: this.state.lng,
    }
    ;(async () => {
      const error = await this.props.onSubmit(item)
      if (error) {
        this.setState({ error, loading: false })
        console.log(error)
      }
      this.setState({ success: true, loading: false })
    })()
  }

  refreshForm = () => {
    navigate('/submit')
  }

  onChange = event => {
    if (event.target.value == null) return
    this.setState({ [event.target.name]: event.target.value })
  }

  onChangePrice = event => {
    this.setState({ price: event.target.value * 100 })
  }

  uploadImg = info => {
    let splitUrl = info.cdnUrl.split('~')
    let imgCount = parseInt(splitUrl[splitUrl.length - 1].replace('/', ''))
    let pictures = []
    for (var i = 0; i < imgCount; i++) {
      let imgUrl = info.cdnUrl + 'nth/' + i + '/'
      pictures.push(imgUrl)
    }
    this.setState(prevState => ({
      item_pictures: pictures,
    }))
  }

  renderStep() {
    const { currentStep, success, error, loading } = this.state
    return (
      <form ref={el => (this.form = el)}>
        {currentStep == 1 ? (
          <FirstStep
            initialData={{ ...this.state }}
            onChange={this.onChange}
            onChangePrice={this.onChangePrice}
            forward={this._moveForward}
          />
        ) : currentStep == 2 ? (
          <SecondStep
            initialData={{ ...this.state }}
            uploadImg={this.uploadImg}
            onChange={this.onChange}
            back={this._moveBackward}
            forward={this._moveForward}
          />
        ) : currentStep == 3 ? (
          <ThirdStep
            initialData={{ ...this.state }}
            onChange={this.onChange}
            back={this._moveBackward}
            submit={this._getAddress}
          />
        ) : (
          <ConfirmationStep
            user={this.props.user}
            data={{ ...this.state }}
            refreshForm={this.refreshForm}
          />
        )}
        <Alert
          type="error"
          visible={error}
          message="We were not able to process your request at this time. Please try
            again later."
        />

        <LoadingDots loading={loading} />
      </form>
    )
  }

  render() {
    let { currentStep } = this.state
    return (
      <FormContainer width="400px">
        <Card>
          <ProgressFormElement
            currentStep={currentStep}
            visible={currentStep <= 3}
          />
          {this.renderStep()}
        </Card>
      </FormContainer>
    )
  }
}
