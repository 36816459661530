import React from 'react'

import { Alert, FormButtonsElement } from 'components/shared/Forms'
import ItemDisplay from 'components/Displays/ItemDisplay'
import Button from 'components/shared/Button'

export default ({ data, user }) => (
  <div>
    <ItemDisplay product={data} status="waiting approval" showStatus={true} />
    <Alert
      type="success"
      visible={true}
      message="You will recieve a notification when your item has been approved."
    />
    {user && user.stripe_user_id ? (
      <div>
        <Alert
          type="warning"
          visible={true}
          message="We were not able to process your request at this time. Please try
        again later."
        />
        <Button big full to="/onboarding">
          Connect with Stripe
        </Button>
      </div>
    ) : (
      <FormButtonsElement
        submitText="View Inventory"
        backText="List Another"
        submit={() => navigate('/dashboard/items')}
        back={() => navigate('/submit')}
      />
    )}
  </div>
)
