import React from 'react'

import {
  FormInput,
  FormButtonsElement,
  FormRow,
  FormCol,
} from 'components/shared/Forms'
import * as categories from 'constants/categories'

export default class FirstStep extends React.Component {
  render() {
    const { initialData } = this.props
    const { item_name, item_price, item_type, item_subtype } = initialData

    const isValid =
      item_name != '' &&
      item_type != '' &&
      item_price != 0 &&
      item_subtype != ''

    return (
      <div>
        <h3>Give it a name and price</h3>
        <FormRow>
          <FormCol>
            <FormInput label="Name">
              <input
                type="text"
                required
                name="item_name"
                id="item_name"
                maxLength="250"
                defaultValue={item_name}
                onChange={this.props.onChange}
              />
            </FormInput>
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol>
            <FormInput label="Price (Daily $)">
              <input
                name="item_price"
                required
                id="item_price"
                type="number"
                min="0"
                max="10000"
                onChange={this.props.onChangePrice}
              />
            </FormInput>
          </FormCol>
        </FormRow>
        <h3>Select a category</h3>
        <FormRow>
          <FormCol>
            <FormInput label="Category">
              <select
                name="item_type"
                required
                value={item_type}
                onChange={this.props.onChange}
              >
                <option value="" disabled="">
                  {' '}
                  Select category
                </option>
                {Object.keys(categories.ITEM_CATEGORIES).map(key => {
                  return (
                    <option value={key} key={key}>
                      {key}
                    </option>
                  )
                })}
              </select>
            </FormInput>
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol>
            {item_type &&
              Object.keys(categories.ITEM_CATEGORIES[item_type]).length !=
                0 && (
                <FormInput label="Sub-category">
                  <select
                    name="item_subtype"
                    required
                    value={item_subtype}
                    onChange={this.props.onChange}
                  >
                    <option value="" disabled="">
                      {' '}
                      Select category
                    </option>
                    {Object.keys(categories.ITEM_CATEGORIES[item_type]).map(
                      key => {
                        return (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        )
                      }
                    )}
                  </select>
                </FormInput>
              )}
          </FormCol>
        </FormRow>
        <FormButtonsElement {...this.props} nextReady={isValid} />
      </div>
    )
  }
}
